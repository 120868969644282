// import pick from 'lodash/pick';

import filterPayload from '@/lib/removeFalsyValues';
import { userRepo } from './user.repo';
import {
  UserForm,
  ChangeUserStatus,
  TableDataPayload,
  CreateUser,
  IUserLocksData,
} from './user.types';

class UserService {
  fetchUsers(payload: UserForm) {
    return userRepo.fetchUsers(filterPayload(payload));
  }

  getUserDetails(id: number) {
    return userRepo.getUserDetails(id);
  }

  editUser(payload: UserForm) {
    const filteredPayload = filterPayload(payload);
    return userRepo.editUser(filteredPayload);
  }

  changeUserStatus(payload: ChangeUserStatus) {
    return userRepo.changeUserStatus(payload);
  }

  getUserNotes(payload: TableDataPayload) {
    return userRepo.getUserNotes(payload);
  }

  formatUserValues(userData: CreateUser) {
    userData.gender = userData.gender.toLowerCase();
    userData.date_of_birth = new Date(userData.date_of_birth).toISOString();
    return userData;
  }

  createNewUser(payload: CreateUser) {
    return userRepo.createNewUser(this.formatUserValues(filterPayload(payload) as CreateUser));
  }

  updateUserLocks(payload: IUserLocksData) {
    return userRepo.updateUserLocks(payload);
  }

  verifyUserAccount = (userId: number) => {
    return userRepo.verifyUserAccount(userId);
  };

  verifyUserFinances = (userId: number) => {
    return userRepo.verifyUserFinances(userId);
  };

  fetchUserImg = (platform_user_id: number, file_name: string) => {
    return userRepo.fetchUserImg(platform_user_id, file_name);
  };

  async getUsername(user_id: number) {
    const usersResponse = await userRepo.fetchUsers({ user_id });
    if (!usersResponse.items[0]) return null;
    const username = usersResponse.items[0].username;
    return username;
  }
}

export const userService = new UserService();
