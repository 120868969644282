import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { ModalName, Modals } from './common.types';

const initialState = () => ({
  modalVisible: Modals.NONE,
  isFetching: false,
});
@Module({ dynamic: true, store, name: 'overlay', namespaced: true })
class Common extends VuexModule {
  modalVisible: ModalName = initialState().modalVisible;
  isFetching = false;
  @Mutation
  setModal(modal: ModalName) {
    this.modalVisible = modal;
  }

  @Mutation
  setIsFetching(isFetching: boolean) {
    this.isFetching = isFetching;
  }

  @Action
  toggleModal(modal: ModalName) {
    this.setModal(modal === this.modalVisible ? Modals.NONE : modal);
  }
}

export const CommonStore = getModule(Common);
