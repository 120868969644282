
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { WrappedFormUtils } from 'ant-design-vue/types/form/form';
import to from 'await-to-js';
import jmbg from 'jmbg';

import { NetworkStatus, WithNetworkStatus } from '@/ui/mixins/WithNetworkStatus';
import { CreateUser, UserInputRule } from '@/modules/userManagement/user.types';
import { UserStore } from '@/modules/userManagement/user.store';
import { isValidAccount, getBankName } from '@/lib/bankAccount';
import { onlyNumbers } from '@/lib/allowOnlyNumbers';
import { formatBase64String, getFileFormat, getBase64 } from '@/lib/imgHelpers';
import { capitalizeFirstLetter } from '@/lib/capitalize';

@Component
export default class CreateNewUser extends Mixins(WithNetworkStatus) {
  form!: WrappedFormUtils;
  ssnNumberLength = 13;
  bankAccNumberLength = 13;
  confirmDirty = false;
  selectedCountry = 'Republika Srbija';
  politicallyExposed = false;
  selectedDocumentToUpload = '';
  loading = false;
  document_image_back = '';
  document_image_front = '';
  personal_photo = '';
  personal_photo_and_document = '';
  bank_proof_doc = '';
  politically_exposed_doc = '';
  fileList: any = [];

  @Prop({ required: true })
  visible!: boolean;

  @Prop({ type: Function })
  close!: () => void;

  beforeCreate() {
    this.form = this.$form.createForm(this);
  }

  onlyNumbers(value: KeyboardEvent) {
    return onlyNumbers(value);
  }

  handleClose() {
    this.close();
    this.form.resetFields();
  }

  successMessage(description: string) {
    return this.$notification.success({
      message: this.$t('common.success') as string,
      description: description as string,
    });
  }

  async handleOk() {
    const [formError] = await to(this.form.validateFields() as any);
    if (formError) return;
    this.setNetworkStatus(NetworkStatus.InProgress);
    const formData = this.form.getFieldsValue() as CreateUser;
    const [err] = await to(UserStore.createNewUser(formData));
    if (err) {
      this.setNetworkStatus(NetworkStatus.Idle);
      return;
    }
    this.setNetworkStatus(NetworkStatus.Idle);
    this.form.resetFields();
    this.close();
    this.successMessage(this.$t('user.userCreatedSuccess') as string);
    UserStore.fetchUsers({ limit: 50, offset: 0 });
  }

  selectCountry(nationality: string) {
    this.selectedCountry = nationality;
    this.form.setFieldsValue({ ['nationality']: nationality });
  }

  validateJmbg(rule: UserInputRule, value: string, callback: Function) {
    const isJmbgValid = jmbg.isValid(value);
    if (value && this.confirmDirty && !isJmbgValid) {
      callback(`${this.$t('user.ssnNotValid')}`);
      this.confirmDirty = false;
    }
    if (value && value.length === this.ssnNumberLength && !isJmbgValid) {
      callback(`${this.$t('user.ssnNotValid')}`);
    } else {
      this.form.setFields({
        ['date_of_birth']: {
          value: '',
        },
        ['gender']: {
          value: '',
        },
      });
      callback();
    }
    if (isJmbgValid) {
      const ssnData = jmbg.decode(value);
      this.form.setFields({
        ['date_of_birth']: {
          value: `${ssnData.year}-${ssnData.month}-${ssnData.day}`,
        },
        ['gender']: {
          value: ssnData.gender,
        },
      });
      callback();
    }
    callback();
  }

  compareToFirstInput(rule: UserInputRule, value: string, callback: Function) {
    const form = this.form;
    if (value && value !== form.getFieldValue(`${rule.field.split('_')[1]}`)) {
      callback(
        `${capitalizeFirstLetter(rule.field.split('_')[1])} ${this.$t('user.inputDoNotMatch')}`
      );
    } else {
      callback();
    }
  }

  validateDataUssage(rule: UserInputRule, value: string, callback: Function) {
    if (!value) {
      callback(`${this.$t('common.requiredValidationError')}`);
    }
    callback();
  }

  validateToNextInput(rule: UserInputRule, value: string, callback: Function) {
    const form = this.form;
    if (value && this.confirmDirty) {
      form.validateFields([`confirm_${rule.field}`], { force: true });
    }
    callback();
  }

  handleConfirmBlur(e: any) {
    const value = e.target.value;
    this.confirmDirty = this.confirmDirty || !!value;
    this.form.validateFields([`${e.target.id}`], { force: true });
  }

  validateBankNumber(rule: UserInputRule, value: string, callback: Function) {
    if (!value) {
      return callback(`${this.$t('user.invalidBankAcc')}`);
    }
    if (value && !isValidAccount(value)) {
      this.form.setFieldsValue({ ['bank_name']: '' });
      return callback(`${this.$t('user.invalidBankAcc')}`);
    }
    this.form.setFieldsValue({ ['bank_name']: getBankName(value) });
    callback();
  }

  showUploadFile({ target }: any) {
    this.politicallyExposed = target.checked;
    if (!this.politicallyExposed) {
      this.form.setFieldsValue({ ['politically_exposed_doc']: null });
    }
  }

  handleFileUpload(type: string, doc: any) {
    this.getBase64(doc.file.originFileObj, (fileUrl: string) => {
      this.form.setFieldsValue({
        [type]: {
          file_base64: formatBase64String(fileUrl),
          file_format: getFileFormat(doc.file.type),
        },
      });
    });
    doc.file.status = ' done';
    if (doc.fileList.length > 1) {
      doc.fileList.shift();
    }
  }

  handleSelectedDocument(value: string) {
    this.selectedDocumentToUpload = value;
  }

  getBase64(img: File, callback: Function) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  handleChange(type: string, file: any) {
    this.loading = true;
    getBase64(file.originFileObj, (fileUrl: string) => {
      this.form.setFieldsValue({
        [type]: {
          file_base64: formatBase64String(fileUrl),
          file_format: getFileFormat(file.type),
        },
      });
      // @ts-ignore: next-line
      this[type] = fileUrl;
      this.loading = false;
    });
  }
}
