var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-row',[_c('user-status-modal',{attrs:{"status":_vm.status,"isModalVisible":_vm.modalVisible,"id":_vm.userId},on:{"handleOk":_vm.handleOkModal,"handleCancel":_vm.handleCancelModal}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEmpty(_vm.userData)),expression:"isEmpty(userData)"}],class:_vm.$style.spinner},[_c('a-spin')],1),_c('a-form',{class:_vm.$style.form,attrs:{"form":_vm.form}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"trigger":"change","label":_vm.$t('user.email')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'email',
              {
                initialValue: _vm.userData.email,
                rules: [
                  {
                    message: _vm.$t('common.requiredValidationError'),
                    required: true,
                  },
                  {
                    type: 'email',
                    message: _vm.$t('user.emailIsNotValid'),
                  } ],
              } ]),expression:"[\n              'email',\n              {\n                initialValue: userData.email,\n                rules: [\n                  {\n                    message: $t('common.requiredValidationError'),\n                    required: true,\n                  },\n                  {\n                    type: 'email',\n                    message: $t('user.emailIsNotValid'),\n                  },\n                ],\n              },\n            ]"}]})],1),_c('a-form-item',{attrs:{"trigger":"change","label":_vm.$t('user.emailConfirm')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'confirm_email',
              {
                initialValue: _vm.userData.email,
                rules: [
                  {
                    message: _vm.$t('common.requiredValidationError'),
                    required: true,
                  },
                  {
                    validator: _vm.validateConfirmEmail,
                  } ],
              } ]),expression:"[\n              'confirm_email',\n              {\n                initialValue: userData.email,\n                rules: [\n                  {\n                    message: $t('common.requiredValidationError'),\n                    required: true,\n                  },\n                  {\n                    validator: validateConfirmEmail,\n                  },\n                ],\n              },\n            ]"}]})],1),_c('a-form-item',{attrs:{"label":_vm.$t('user.securityQuestion')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'security_question',
              {
                initialValue: _vm.userData.security_question,
              } ]),expression:"[\n              'security_question',\n              {\n                initialValue: userData.security_question,\n              },\n            ]"}]})],1),_c('a-form-item',{attrs:{"label":_vm.$t('user.securityAnswer')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'security_answer',
              {
                initialValue: _vm.userData.security_answer,
              } ]),expression:"[\n              'security_answer',\n              {\n                initialValue: userData.security_answer,\n              },\n            ]"}]})],1),_c('a-form-item',{attrs:{"label":_vm.$t('user.nationality')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'nationality',
              {
                initialValue: _vm.userData.nationality,
                rules: [
                  {
                    required: true,
                    message: _vm.$t('common.requiredValidationError'),
                  } ],
              } ]),expression:"[\n              'nationality',\n              {\n                initialValue: userData.nationality,\n                rules: [\n                  {\n                    required: true,\n                    message: $t('common.requiredValidationError'),\n                  },\n                ],\n              },\n            ]"}],attrs:{"options":_vm.countryOptions}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('user.riskLevel')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'risk_level',
              {
                initialValue: _vm.userData.risk_level,
                rules: [
                  {
                    required: true,
                    message: _vm.$t('common.requiredValidationError'),
                  } ],
              } ]),expression:"[\n              'risk_level',\n              {\n                initialValue: userData.risk_level,\n                rules: [\n                  {\n                    required: true,\n                    message: $t('common.requiredValidationError'),\n                  },\n                ],\n              },\n            ]"}]})],1),_c('div',{class:_vm.$style.uneditableLabel},[_vm._v(_vm._s(_vm.$t('user.dateOfRegistration'))+":")]),_c('div',{class:_vm.$style.uneditableField},[_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.userData.created_at || ''))+" ")]),_c('div',{class:_vm.$style.uneditableLabel},[_vm._v(_vm._s(_vm.$t('user.lastLogin'))+":")]),_c('div',{class:_vm.$style.uneditableField},[_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.userData.last_activity || ''))+" ")])],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"trigger":"change","label":_vm.$t('user.phone')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'phone_number',
              {
                initialValue: _vm.userData.phone_number,
                rules: [
                  {
                    message: _vm.$t('common.requiredValidationError'),
                    required: true,
                  } ],
              } ]),expression:"[\n              'phone_number',\n              {\n                initialValue: userData.phone_number,\n                rules: [\n                  {\n                    message: $t('common.requiredValidationError'),\n                    required: true,\n                  },\n                ],\n              },\n            ]"}],on:{"keydown":_vm.onlyNumbers}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('user.note')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'note_for_user',
              {
                initialValue: _vm.userData.note,
              } ]),expression:"[\n              'note_for_user',\n              {\n                initialValue: userData.note,\n              },\n            ]"}]})],1),_c('div',{class:_vm.$style.uneditableLabel},[_vm._v(_vm._s(_vm.$t('user.balance'))+":")]),_c('div',{class:_vm.$style.uneditableField},[_vm._v(" "+_vm._s(_vm.userData.synced_balance)+" ")]),_c('div',{class:_vm.$style.uneditableLabel},[_vm._v(_vm._s(_vm.$t('user.promoBalance'))+":")]),_c('div',{class:_vm.$style.uneditableField},[_vm._v(" "+_vm._s(_vm.userData.synced_promo_balance)+" ")])],1)],1),_c('a-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isEmpty(_vm.userData)),expression:"!isEmpty(userData)"}],class:_vm.$style.buttonWrapper,attrs:{"span":24}},[_c('a-button',{attrs:{"type":"danger","disabled":!_vm.form.isFieldsTouched()},on:{"click":_vm.resetForm}},[_vm._v(" "+_vm._s(_vm.$t('user.reset'))+" ")]),_c('a-button',{class:_vm.$style.saveButton,attrs:{"type":"primary"},on:{"click":_vm.editUser}},[_vm._v(" "+_vm._s(_vm.$t('common.save'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }