var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{class:_vm.$style.container,attrs:{"form":_vm.form}},[_c('img-preview-modal',{attrs:{"imgName":_vm.imgToPreview,"visible":_vm.showPreviewModal,"close":_vm.handleClosePreview}}),_c('a-form-item',{attrs:{"label":_vm.$t('user.bankAccNumber')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'bank_acc_number',
        {
          initialValue: _vm.selectedUser.bank_acc_number,
          rules: [
            {
              validator: _vm.validateBankNumber,
            } ],
        } ]),expression:"[\n        'bank_acc_number',\n        {\n          initialValue: selectedUser.bank_acc_number,\n          rules: [\n            {\n              validator: validateBankNumber,\n            },\n          ],\n        },\n      ]"}],attrs:{"maxLength":_vm.bankAccNumberLength},on:{"keydown":_vm.onlyNumbers}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('user.bankName')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'bank_name',
        {
          initialValue: _vm.initialBankName,
        } ]),expression:"[\n        'bank_name',\n        {\n          initialValue: initialBankName,\n        },\n      ]"}],attrs:{"disabled":""}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('user.sourceIncome')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['source_income', { initialValue: _vm.selectedUser.source_income }]),expression:"['source_income', { initialValue: selectedUser.source_income }]"}]})],1),_c('a-form-item',{attrs:{"label":_vm.$t('user.uploadBankProof')}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:(['bank_proof_doc']),expression:"['bank_proof_doc']"}],attrs:{"name":"bank_proof_doc","list-type":"picture-card","show-upload-list":false,"customRequest":function () { return false; }},on:{"change":_vm.handleChangePhoto}},[(_vm.bank_proof_doc)?_c('img',{class:_vm.$style.documentImage,attrs:{"src":_vm.bank_proof_doc}}):_c('div',{class:_vm.$style.documentIcons},[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("Upload")])],1)]),_c('span',{class:_vm.$style.previewPhotoBtn,on:{"click":function($event){return _vm.handleOpenPreview('bank_proof_doc')}}},[_vm._v(_vm._s(_vm.$t('user.preview')))])],1),_c('div',[(_vm.showFinancialVerifyBtn)?_c('a-button',{class:_vm.$style.financialVerifyBtn,attrs:{"type":"primary"},on:{"click":_vm.handleFinanciallyValidateAccount}},[_vm._v(_vm._s(_vm.$t('user.activateFinances')))]):_vm._e()],1),_c('div',[_c('a-button',{staticStyle:{"margin-right":"15px"},attrs:{"type":"danger","disabled":!_vm.form.isFieldsTouched()},on:{"click":_vm.resetForm}},[_vm._v(" "+_vm._s(_vm.$t('user.reset'))+" ")]),_c('a-button',{staticClass:"mr-12",attrs:{"type":"primary","htmlType":"submit"},on:{"click":_vm.handleSubmit}},[_vm._v(_vm._s(_vm.$t('common.save')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }