
import { Mixins, Component } from 'vue-property-decorator';
import { WrappedFormUtils } from 'ant-design-vue/types/form/form';

import { WithDataTable } from '@/ui/mixins/WithDataTable';
import { UserPayload, UserData } from '@/modules/userManagement/user.types';
import { LONG_DATE_TIME } from '@/constants';
import { UserStore } from '@/modules/userManagement/user.store';
import { DateFormatter } from '@/ui/components/CellRenderers/DateFormatter';
import UserStatusCell from '@/ui/components/CellRenderers/UserStatusCell';
import YesNoCell from '@/ui/components/CellRenderers/YesNoCell';
import { WithNetworkStatus, NetworkStatus } from '@/ui/mixins/WithNetworkStatus';
import UserOverview from './components/UserOverview/UserOverview.vue';
import CreateNewUserModal from './components/CreateNewUserModal.vue';
import { CommonStore, Modals } from '@/modules/common';
import { onlyNumbers } from '@/lib/allowOnlyNumbers';
// import CreateManualUserTransactionModal from './components/CreateManualUserTransactionModal/CreateManualUserTransactionModal.vue';
import { EventStatusLegend } from '@/ui/components/EventStatusLegend';
import { statusColorMap } from '@/modules/userManagement/user.constants';

@Component({
  name: 'UserManagementPage',
  components: {
    UserOverview,
    CreateNewUserModal,
    // CreateManualUserTransactionModal,
    EventStatusLegend,
  },
})
export default class UserManagementPage extends Mixins(WithDataTable, WithNetworkStatus) {
  form!: WrappedFormUtils;

  get tableProps() {
    return {
      columnDefs: [
        {
          headerName: this.$t('user.username'),
          field: 'username',
          width: 180,
          cellStyle: (params: any) => {
            if (!params.data) return {};
            return { borderLeft: `7px solid ${statusColorMap[params.data.status]}` };
          },
        },
        {
          headerName: this.$t('user.name'),
          width: 140,
          field: 'first_name',
        },
        { headerName: this.$t('user.surname'), field: 'last_name', width: 140 },
        { headerName: this.$t('user.ssn'), field: 'ssn', width: 130 },
        { headerName: this.$t('user.email'), field: 'email', width: 120 },
        { headerName: this.$t('user.balans'), field: 'synced_balance', width: 120 },

        { headerName: this.$t('user.promoBalans'), field: 'synced_promo_balance', width: 120 },
        {
          headerName: this.$t('user.lastActivity'),
          field: 'last_activity',
          cellRenderer: DateFormatter,
          width: 100,
        },
        {
          headerName: this.$t('user.created'),
          field: 'created_at',
          cellRenderer: DateFormatter,
          width: 150,
        },
        {
          headerName: this.$t('user.status'),
          field: 'is_active',
          width: 100,
          cellRenderer: UserStatusCell,
        },
        {
          headerName: this.$t('user.validated'),
          field: 'is_validated',
          width: 100,
          cellRenderer: YesNoCell,
        },
        {
          headerName: this.$t('user.isLocked'),
          field: 'locked_user',
          cellRenderer: YesNoCell,
          width: 80,
        },
        {
          headerName: this.$t('user.payoutBlocked'),
          field: 'payment_disabled',
          cellRenderer: YesNoCell,
          width: 80,
        },
      ],
      rowModelType: 'infinite',
    };
  }

  get selectedUser() {
    return UserStore.user;
  }

  get longDateFormat() {
    return LONG_DATE_TIME;
  }

  get tableData() {
    return UserStore.users;
  }

  get totalRecords() {
    return UserStore.totalUsers;
  }

  get userId() {
    return UserStore.userId;
  }

  get modalVisible() {
    return CommonStore.modalVisible;
  }

  beforeCreate() {
    this.form = this.$form.createForm(this);
  }

  onlyNumbers(value: KeyboardEvent) {
    return onlyNumbers(value);
  }

  onFilterSubmit(e: any) {
    e.preventDefault();
    this.gridApi?.setDatasource(this.dataSource);
    this.gridApi?.showLoadingOverlay();
    UserStore.clearUsersStore();
  }

  clearFilters() {
    this.form.resetFields();
    this.gridApi?.setDatasource(this.dataSource);
    UserStore.clearUsersStore();
  }

  async fetchData() {
    const filterData = this.form.getFieldsValue();
    this.gridApi?.showLoadingOverlay();
    this.setNetworkStatus(NetworkStatus.InProgress);
    await UserStore.fetchUsers({
      ...filterData,
      limit: this.limit,
      offset: this.offset,
    }).catch(() => {
      this.setNetworkStatus(NetworkStatus.Error);
      this.gridApi?.hideOverlay();
    });
    this.setNetworkStatus(NetworkStatus.Success);
    this.gridApi?.hideOverlay();
  }

  onRowClicked({ data }: UserPayload) {
    UserStore.setActiveId(data.id);
  }

  userChanged(val: UserData) {
    this.gridApi?.forEachNode(node => {
      if (node.data.id === this.userId) {
        node.setData({ ...node.data, ...val });
      }
    });
  }

  toggleModal() {
    CommonStore.toggleModal(Modals.CREATE_USER);
  }

  // toggleTransactionModal() {
  //   CommonStore.toggleModal(Modals.MANUAL_USER_TRANSACTION_ENTRY);
  // }
}
