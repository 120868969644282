import { TranslateResult } from 'vue-i18n';

export type ModalName =
  | Modals.COMPARE_ODDS
  | Modals.NEW_EVENT
  | Modals.STATE
  | Modals.TEMPLATES
  | Modals.NOTE
  | Modals.SETTLE_ALL
  | Modals.SETTLE_PERIOD
  | Modals.SETTLE_PERIOD1530
  | Modals.SETTLEMENT_FOOTBALL_TIME
  | Modals.SETTLEMENT_NOTES
  | Modals.MATCH_DATA
  | Modals.PLAYER_MATCH_DATA
  | Modals.LAST_FIFTY
  | Modals.GAME_SETTINGS
  | Modals.POPUP_SETTINGS
  | Modals.CREATE_USER
  | Modals.MANUAL_ODD_ENTRY
  | Modals.MANUAL_USER_TRANSACTION_ENTRY
  | Modals.SETTLE_LIVE_PLAYER
  | Modals.NONE;

export enum Modals {
  COMPARE_ODDS = 'compareOdds',
  NEW_EVENT = 'newEvent',
  STATE = 'state',
  TEMPLATES = 'templates',
  NOTE = 'note',
  LAST_FIFTY = 'lastFifty',
  SETTLE_ALL = 'settleAll',
  SETTLE_PERIOD = 'settlePeriods',
  SETTLE_PERIOD1530 = 'settlePeriods1530',
  SETTLEMENT_FOOTBALL_TIME = 'settlementFootballTime',
  SETTLEMENT_NOTES = 'settlementNotes',
  MATCH_DATA = 'matchData',
  PLAYER_MATCH_DATA = 'playerMatchData',
  GAME_SETTINGS = 'gameSettings',
  POPUP_SETTINGS = 'popupSettings',
  CREATE_USER = 'createUser',
  MANUAL_ODD_ENTRY = 'manualOddEntry',
  MANUAL_USER_TRANSACTION_ENTRY = 'createManualTransaction',
  SETTLE_LIVE_PLAYER = 'settleLivePlayer',
  NONE = '',
}

export interface InputProps {
  key?: any;
  span?: any;
  colClass?: any;
  isDisabled?: any;
  type: string;
  placeholder?: TranslateResult;
  vDecorator: any;
  selectProps?: any;
  showSearch?: boolean;
  onChange?: any;
  onSearch?: any; // this added for lazy loading betradar competitors in configuration/competitors/
  label: TranslateResult;
  class: any;
  optionFilterProp?: 'children' | string | null;
  allowClear?: boolean;
  filterOption?: boolean;
}

export type CellType = 'EVENT-MANAGEMENT' | 'PREMATCH' | 'LIVE' | 'SETTLEMENT' | 'PLAYER';

export type OperatorNote = {
  createdAt: string;
  note: string;
  operatorName: string;
  paramsList?: string[];
  userId: number;
};
