export interface PermittedKeys {
  [key: string]: boolean;
}

const premittedKeys: PermittedKeys = {
  Backspace: true,
  Tab: true,
  ArrowDown: true,
  ArrowUp: true,
  ArrowRight: true,
  ArrowLeft: true,
  Delete: true,
  Digit0: true,
  Digit1: true,
  Digit2: true,
  Digit3: true,
  Digit4: true,
  Digit5: true,
  Digit6: true,
  Digit7: true,
  Digit8: true,
  Digit9: true,
  Numpad0: true,
  Numpad1: true,
  Numpad2: true,
  Numpad3: true,
  Numpad4: true,
  Numpad5: true,
  Numpad6: true,
  Numpad7: true,
  Numpad8: true,
  Numpad9: true,
  Equal: true,
};

export const onlyNumbers = (event: KeyboardEvent) => {
  if (!premittedKeys[event.code]) return event.preventDefault();
};
