
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { UserData } from '@/modules/userManagement/user.types';
import { UserStore } from '@/modules/userManagement/user.store';
import UserDetails from './components/UserDetails.vue';
import UserNote from './components/UserNote.vue';
import UserAccountManagement from './components/UserAccountManagement.vue';
import UserCheckboxFields from './components/UserCheckboxFields.vue';
import UserValidationDetails from './components/UserValidationDetails.vue';
import UserAddressDetails from './components/UserAddressDetails.vue';
import UserBankDetails from './components/UserBankDetails.vue';
import UserRemoteUsernames from './components/UserRemoteUsernames.vue';
import NotesWrapper from './components/NotesWrapper.vue';
import { statusColorMap } from '@/modules/userManagement/user.constants';

@Component({
  name: 'UserOverview',
  components: {
    UserDetails,
    UserNote,
    UserAccountManagement,
    UserCheckboxFields,
    UserValidationDetails,
    UserAddressDetails,
    UserBankDetails,
    UserRemoteUsernames,
    NotesWrapper,
  },
})
export default class UserOverview extends Vue {
  @Prop({ required: true })
  user!: UserData;

  get hasUserId() {
    if (UserStore.userId === -1) return false;
    return true;
  }

  get getStatusColor() {
    if (!this.user.status) return {};
    return { backgroundColor: statusColorMap[this.user.status], borderRadius: 0 };
  }

  onCloseHandler() {
    UserStore.clearUser();
  }

  userChanged(userData: UserData) {
    this.$emit('userChanged', userData);
  }
}
