
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';

@Component
export default class EventStatusLegend extends Vue {
  @Prop({ required: true, type: Array })
  visible!: string[];

  get states() {
    return [
      { key: 'created', label: this.$t('event.createdState') },
      { key: 'selected', label: this.$t('event.selected') },
      { key: 'validated', label: this.$t('event.validated') },
      { key: 'published', label: this.$t('event.published') },
      { key: 'hidden', label: this.$t('event.hidden') },
      { key: 'completed', label: this.$t('event.completed') },
      { key: 'notConfirmed', label: this.$t('event.notConfirmed') },
      //additional states for user management
      { key: 'registered', label: this.$t('user.registered') },
      { key: 'verifyingInProgress', label: this.$t('user.verifyingInProgress') },
      { key: 'verified', label: this.$t('user.verified') },
      {
        key: 'financiallyVerifyingInProgress',
        label: this.$t('user.financiallyVerifyingInProgress'),
      },
      { key: 'approved', label: this.$t('user.approved') },
    ];
  }
}
