var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit.apply(null, arguments)}}},[_c('img-preview-modal',{attrs:{"imgName":_vm.imgToPreview,"visible":_vm.showPreviewModal,"close":_vm.handleClosePreview}}),_c('a-row',{attrs:{"type":"flex"}},[_c('a-col',{class:_vm.$style.photosSection,attrs:{"flex":"1"}},[_c('a-form-item',{attrs:{"label":_vm.$t('user.chooseDocumentToUpload')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['document_type', { initialValue: _vm.initialDocumentTypeValue }]),expression:"['document_type', { initialValue: initialDocumentTypeValue }]"}],staticStyle:{"width":"10rem"},attrs:{"placeholder":_vm.$t('user.selectType'),"options":_vm.documentTypeOptions}})],1),_c('a-row',{attrs:{"type":"flex"}},[_c('a-form-item',{staticClass:"p-6 m-0",attrs:{"label":_vm.$t('user.idFrontImage')}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:(['document_image_front']),expression:"['document_image_front']"}],attrs:{"list-type":"picture-card","show-upload-list":false,"customRequest":function () { return false; }},on:{"change":function (ref) {
          var file = ref.file;

          return _vm.handleFileChange('document_image_front', file);
}}},[(_vm.document_image_front)?_c('img',{class:_vm.$style.documentImage,attrs:{"src":_vm.document_image_front,"alt":"avatar"}}):_c('div',{class:_vm.$style.documentIcons},[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',[_vm._v("Upload")])],1)]),_c('span',{class:_vm.$style.previewPhotoBtn,on:{"click":function($event){return _vm.handleOpenPreview('document_image_front')}}},[_vm._v(_vm._s(_vm.$t('user.preview')))])],1),_c('a-form-item',{staticClass:"p-6 m-0",attrs:{"label":_vm.$t('user.idBackImage')}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:(['document_image_back']),expression:"['document_image_back']"}],attrs:{"list-type":"picture-card","show-upload-list":false,"customRequest":function () { return false; }},on:{"change":function (ref) {
          var file = ref.file;

          return _vm.handleFileChange('document_image_back', file);
}}},[(_vm.document_image_back)?_c('img',{class:_vm.$style.documentImage,attrs:{"src":_vm.document_image_back,"alt":"avatar"}}):_c('div',{class:_vm.$style.documentIcons},[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',[_vm._v("Upload")])],1)]),_c('span',{class:_vm.$style.previewPhotoBtn,on:{"click":function($event){return _vm.handleOpenPreview('document_image_back')}}},[_vm._v(_vm._s(_vm.$t('user.preview')))])],1)],1),_c('a-row',{attrs:{"type":"flex"}},[_c('a-form-item',{directives:[{name:"decorator",rawName:"v-decorator",value:(['personal_photo']),expression:"['personal_photo']"}],staticClass:"p-6 m-0",attrs:{"label":_vm.$t('user.uploadPersonPhotography')}},[_c('a-upload',{attrs:{"list-type":"picture-card","show-upload-list":false,"customRequest":function () { return false; }},on:{"change":function (ref) {
          var file = ref.file;

          return _vm.handleFileChange('personal_photo', file);
}}},[(_vm.personal_photo)?_c('img',{class:_vm.$style.documentImage,attrs:{"src":_vm.personal_photo,"alt":"avatar"}}):_c('div',{class:_vm.$style.documentIcons},[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',[_vm._v("Upload")])],1)]),_c('span',{class:_vm.$style.previewPhotoBtn,on:{"click":function($event){return _vm.handleOpenPreview('personal_photo')}}},[_vm._v(_vm._s(_vm.$t('user.preview')))])],1),_c('a-form-item',{staticClass:"p-6 m-0",attrs:{"label":_vm.$t('user.uploadFaceWithDoc')}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:(['personal_photo_and_document']),expression:"['personal_photo_and_document']"}],attrs:{"list-type":"picture-card","show-upload-list":false,"customRequest":function () { return false; }},on:{"change":function (ref) {
          var file = ref.file;

          return _vm.handleFileChange('personal_photo_and_document', file);
}}},[(_vm.personal_photo_and_document)?_c('img',{class:_vm.$style.documentImage,attrs:{"src":_vm.personal_photo_and_document,"alt":"avatar"}}):_c('div',{class:_vm.$style.documentIcons},[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',[_vm._v("Upload")])],1)]),_c('span',{class:_vm.$style.previewPhotoBtn,on:{"click":function($event){return _vm.handleOpenPreview('personal_photo_and_document')}}},[_vm._v(_vm._s(_vm.$t('user.preview')))])],1)],1),(_vm.showActivateUserBtn)?_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleActivateAccount}},[_vm._v(" "+_vm._s(_vm.$t('user.activateAccount'))+" ")]):_vm._e()],1),_c('a-col',{staticClass:"pl-30 pr-30",attrs:{"flex":"1"}},[_c('a-form-item',{attrs:{"label":_vm.$t('user.name')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'first_name',
            {
              initialValue: _vm.userData.first_name,
              rules: [
                {
                  required: true,
                  message: _vm.$t('common.requiredValidationError'),
                } ],
            } ]),expression:"[\n            'first_name',\n            {\n              initialValue: userData.first_name,\n              rules: [\n                {\n                  required: true,\n                  message: $t('common.requiredValidationError'),\n                },\n              ],\n            },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":_vm.$t('user.surname')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'last_name',
            {
              initialValue: _vm.userData.last_name,
              rules: [
                {
                  required: true,
                  message: _vm.$t('common.requiredValidationError'),
                } ],
            } ]),expression:"[\n            'last_name',\n            {\n              initialValue: userData.last_name,\n              rules: [\n                {\n                  required: true,\n                  message: $t('common.requiredValidationError'),\n                },\n              ],\n            },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":_vm.$t('user.ssn')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'ssn',
            {
              initialValue: _vm.userData.ssn,
              rules: [
                {
                  message: _vm.$t('common.requiredValidationError'),
                },
                {
                  validator: _vm.validateJmbg,
                } ],
            } ]),expression:"[\n            'ssn',\n            {\n              initialValue: userData.ssn,\n              rules: [\n                {\n                  message: $t('common.requiredValidationError'),\n                },\n                {\n                  validator: validateJmbg,\n                },\n              ],\n            },\n          ]"}],attrs:{"maxLength":_vm.ssnNumberLength},on:{"keydown":_vm.onlyNumbers}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('user.dateOfBirth')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'date_of_birth',
            {
              initialValue: _vm.getBirthdateFromJmbg(),
            } ]),expression:"[\n            'date_of_birth',\n            {\n              initialValue: getBirthdateFromJmbg(),\n            },\n          ]"}],attrs:{"disabled":""}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('user.gender')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'gender',
            {
              initialValue: _vm.userData.gender,
            } ]),expression:"[\n            'gender',\n            {\n              initialValue: userData.gender,\n            },\n          ]"}],staticStyle:{"width":"10rem"},attrs:{"placeholder":_vm.$t('user.selectGender'),"options":_vm.genderOptions}})],1)],1)],1),_c('a-button',{class:_vm.$style.btn,attrs:{"htmlType":"submit"}},[_vm._v(_vm._s(_vm.$t('common.save')))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }