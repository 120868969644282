
import { Component, Vue, Prop } from 'vue-property-decorator';
import { UserStore } from '@/modules/userManagement/user.store';

@Component({ name: 'ImgPreviewModal' })
export default class ImgPreviewModal extends Vue {
  @Prop({ required: true })
  visible!: boolean;

  @Prop({ type: Function })
  close!: () => void;

  @Prop()
  imgName!:
    | 'document_image_front'
    | 'document_image_back'
    | 'personal_photo'
    | 'personal_photo_and_document'
    | 'bank_proof_doc'
    | 'politically_exposed_doc';

  get userData() {
    return UserStore.user;
  }

  get imgPath() {
    return `${process.env.VUE_APP_STATIC_ASSETS_PATH}${this.userData[this.imgName]}`;
  }
}
