export const getFileFormat = (file: string) => file.split('/').pop();

export const formatBase64String = (file: string) => file.split(',').pop();

export const getFileExtension = (file: string) => file.split(',')[0];

export const getBase64 = (img: File, callback: Function) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};
