
import Vue from 'vue';
import { WrappedFormUtils } from 'ant-design-vue/types/form/form';
import { Component, Watch } from 'vue-property-decorator';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import to from 'await-to-js';
import { TranslateResult } from 'vue-i18n';

import { onlyNumbers } from '@/lib/allowOnlyNumbers';
import { UserData } from '@/modules/userManagement/user.types';
import { UserStore } from '@/modules/userManagement/user.store';
import { FULL_DATE_TIME } from '@/constants';
import UserStatusModal from '../../ChangeUserStatusModal.vue';
import ChangeUserDetailsModal from '../../ChangeUserDetailsModal.vue';
import { generateSelectOptionsFromArr } from '@/lib/generateSelectOptionsFromArr';

@Component({
  name: 'UserBasicDetails',
  components: { UserStatusModal, ChangeUserDetailsModal },
})
export default class UserBasicDetails extends Vue {
  form!: WrappedFormUtils;
  modalVisible = false;
  formData = {};
  status = '';
  countryOptions = generateSelectOptionsFromArr(['Srbija', 'Crna Gora', 'Bosna i Hercegovina']);
  onlyNumbers = onlyNumbers;

  get userId() {
    return UserStore.userId;
  }

  get userData() {
    return UserStore.user;
  }

  @Watch('userData')
  resetFormOnDataChanged() {
    this.form.resetFields();
  }

  beforeCreate() {
    this.form = this.$form.createForm(this);
  }

  mounted() {
    this.fetchUserDetails(this.userId);
  }

  isEmpty(val: UserData) {
    return isEmpty(val);
  }

  successMessage(description: TranslateResult) {
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: description as string,
    });
  }

  handleOkModal() {
    this.modalVisible = false;
  }

  handleCancelModal() {
    this.modalVisible = false;
  }

  selectedStatus(status: string) {
    this.modalVisible = true;
    this.status = status;
  }

  formatDateTime(val: string) {
    return val ? moment(val).format(FULL_DATE_TIME) : null;
  }

  resetForm() {
    this.form.resetFields();
  }

  async editUser() {
    const [error] = await to(this.form.validateFields() as any);
    if (error) return;
    const formData = this.form.getFieldsValue();
    this.$parent.$emit('submit', formData);
  }

  fetchUserDetails(id: number) {
    UserStore.getUserDetails(id);
  }

  validateConfirmEmail(_rule: any, value: string, callback: Function) {
    const emailValue = this.form.getFieldValue('email');
    if (value && value !== emailValue) callback(this.$t('user.emailsDoNotMatch'));
    else callback();
  }
}
