var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"visible":_vm.visible,"confirmLoading":_vm.isLoading,"width":"90%","okText":_vm.$t('common.save'),"title":_vm.$t('user.createNewUser'),"dialogClass":_vm.$style.dialogClass,"destroyOnClose":""},on:{"cancel":_vm.handleClose,"ok":_vm.handleOk}},[_c('a-form',{class:_vm.$style.form,attrs:{"form":_vm.form}},[_c('a-row',{class:_vm.$style.rowWrapper},[_c('a-col',{attrs:{"span":16}},[_c('a-col',{staticClass:"pl-50",class:_vm.$style.basicInfo,attrs:{"span":12}},[_c('h4',{class:_vm.$style.width100},[_vm._v(_vm._s(_vm.$t('user.basicInfo')))]),_c('a-row',{attrs:{"span":24,"gutter":[16, 16]}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"trigger":"change","label":_vm.$t('user.name')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'first_name',
                    {
                      rules: [
                        {
                          required: true,
                          message: _vm.$t('common.requiredValidationError'),
                        } ],
                    } ]),expression:"[\n                    'first_name',\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: $t('common.requiredValidationError'),\n                        },\n                      ],\n                    },\n                  ]"}]})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"trigger":"change","label":_vm.$t('user.surname')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'last_name',
                    {
                      rules: [
                        {
                          required: true,
                          message: _vm.$t('common.requiredValidationError'),
                        } ],
                    } ]),expression:"[\n                    'last_name',\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: $t('common.requiredValidationError'),\n                        },\n                      ],\n                    },\n                  ]"}]})],1)],1)],1),_c('a-form-item',{class:_vm.$style.width100,attrs:{"trigger":"change","label":_vm.$t('user.username')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'username',
                {
                  rules: [
                    {
                      required: true,
                      message: _vm.$t('common.requiredValidationError'),
                    } ],
                } ]),expression:"[\n                'username',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: $t('common.requiredValidationError'),\n                    },\n                  ],\n                },\n              ]"}]})],1),_c('a-form-item',{class:_vm.$style.width100,attrs:{"trigger":"change","label":_vm.$t('user.email')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'email',
                {
                  rules: [
                    {
                      required: true,
                      message: _vm.$t('common.requiredValidationError'),
                    },
                    {
                      validator: _vm.validateToNextInput,
                    },
                    { type: 'email', message: _vm.$t('user.emailIsNotValid') } ],
                } ]),expression:"[\n                'email',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: $t('common.requiredValidationError'),\n                    },\n                    {\n                      validator: validateToNextInput,\n                    },\n                    { type: 'email', message: $t('user.emailIsNotValid') },\n                  ],\n                },\n              ]"}]})],1),_c('a-form-item',{class:_vm.$style.width100,attrs:{"trigger":"change","label":_vm.$t('user.emailConfirm')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'confirm_email',
                {
                  rules: [
                    {
                      required: true,
                      message: _vm.$t('common.requiredValidationError'),
                    },
                    {
                      validator: _vm.compareToFirstInput,
                    },
                    { type: 'email', message: _vm.$t('user.emailIsNotValid') } ],
                } ]),expression:"[\n                'confirm_email',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: $t('common.requiredValidationError'),\n                    },\n                    {\n                      validator: compareToFirstInput,\n                    },\n                    { type: 'email', message: $t('user.emailIsNotValid') },\n                  ],\n                },\n              ]"}]})],1),_c('a-row',{attrs:{"span":24,"gutter":[16, 16]}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"trigger":"change","label":_vm.$t('user.password')}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'password',
                    {
                      rules: [
                        {
                          required: true,
                          message: _vm.$t('common.requiredValidationError'),
                        },
                        {
                          validator: _vm.validateToNextInput,
                        } ],
                    } ]),expression:"[\n                    'password',\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: $t('common.requiredValidationError'),\n                        },\n                        {\n                          validator: validateToNextInput,\n                        },\n                      ],\n                    },\n                  ]"}],attrs:{"allow-clear":"","autocomplete":"new-password","placeholder":"Password"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"pl-10",attrs:{"trigger":"change","label":_vm.$t('user.confirmPassword')}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'confirm_password',
                    {
                      rules: [
                        {
                          required: true,
                          message: _vm.$t('common.requiredValidationError'),
                        },
                        {
                          validator: _vm.compareToFirstInput,
                        } ],
                    } ]),expression:"[\n                    'confirm_password',\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: $t('common.requiredValidationError'),\n                        },\n                        {\n                          validator: compareToFirstInput,\n                        },\n                      ],\n                    },\n                  ]"}],attrs:{"allow-clear":"","autocomplete":"","placeholder":"Password"},on:{"blur":_vm.handleConfirmBlur}})],1)],1)],1),_c('a-form-item',{class:_vm.$style.width100,attrs:{"trigger":"change","label":_vm.$t('user.ssn')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'ssn',
                {
                  rules: [
                    {
                      required: true,
                      message: _vm.$t('common.requiredValidationError'),
                    },
                    {
                      validator: _vm.validateJmbg,
                    } ],
                } ]),expression:"[\n                'ssn',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: $t('common.requiredValidationError'),\n                    },\n                    {\n                      validator: validateJmbg,\n                    },\n                  ],\n                },\n              ]"}],attrs:{"maxLength":_vm.ssnNumberLength},on:{"keydown":_vm.onlyNumbers,"blur":_vm.handleConfirmBlur}})],1),_c('a-form-item',{class:_vm.$style.width100,attrs:{"label":_vm.$t('user.securityQuestion')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['security_question']),expression:"['security_question']"}]})],1),_c('a-form-item',{class:_vm.$style.width100,attrs:{"label":_vm.$t('user.securityAnswer')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['security_answer']),expression:"['security_answer']"}]})],1),_c('a-row',{attrs:{"span":24,"gutter":[16, 16]}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":_vm.$t('user.dateOfBirth')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['date_of_birth']),expression:"['date_of_birth']"}],attrs:{"disabled":""}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"pl-10",attrs:{"label":_vm.$t('user.gender')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['gender']),expression:"['gender']"}],attrs:{"disabled":""}})],1)],1)],1),_c('a-row',{attrs:{"span":24,"gutter":[16, 16]}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"trigger":"change","label":_vm.$t('user.phoneNumber')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'phone_number',
                    {
                      rules: [
                        {
                          required: true,
                          message: _vm.$t('common.requiredValidationError'),
                        } ],
                    } ]),expression:"[\n                    'phone_number',\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: $t('common.requiredValidationError'),\n                        },\n                      ],\n                    },\n                  ]"}],on:{"change":_vm.onlyNumbers}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"pl-10",attrs:{"label":_vm.$t('user.nationality')}},[_c('a-dropdown',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'nationality',
                    {
                      initialValue: _vm.selectedCountry,
                    } ]),expression:"[\n                    'nationality',\n                    {\n                      initialValue: selectedCountry,\n                    },\n                  ]"}],class:_vm.$style.dropdownMenu,attrs:{"trigger":['click'],"placement":"bottomCenter"}},[_c('a-button',{class:_vm.$style.dropdownButton,attrs:{"block":"","icon":""}},[_vm._v(" "+_vm._s(_vm.selectedCountry)+" ")]),_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},_vm._l(([
                        'Crna Gora',
                        'Bosna i Hercegovina',
                        'Republika Srbija' ]),function(nationality){return _c('a-menu-item',{key:nationality,on:{"click":function($event){return _vm.selectCountry(nationality)}}},[_vm._v(" "+_vm._s(nationality)+" ")])}),1)],1)],1)],1)],1)],1),_c('a-col',{staticClass:"pl-50",attrs:{"span":12}},[_c('h4',{class:_vm.$style.width100},[_vm._v(_vm._s(_vm.$t('user.address')))]),_c('a-form-item',{attrs:{"trigger":"change","label":_vm.$t('user.streetName')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'street_name',
                {
                  rules: [
                    {
                      required: true,
                      message: _vm.$t('common.requiredValidationError'),
                    } ],
                } ]),expression:"[\n                'street_name',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: $t('common.requiredValidationError'),\n                    },\n                  ],\n                },\n              ]"}]})],1),_c('a-form-item',{attrs:{"trigger":"change","label":_vm.$t('user.streetNumber')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'street_number',
                {
                  rules: [
                    {
                      required: true,
                      message: _vm.$t('common.requiredValidationError'),
                    } ],
                } ]),expression:"[\n                'street_number',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: $t('common.requiredValidationError'),\n                    },\n                  ],\n                },\n              ]"}]})],1),_c('a-form-item',{attrs:{"label":_vm.$t('user.aptNumber')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['apartment_number']),expression:"['apartment_number']"}]})],1),_c('a-form-item',{attrs:{"trigger":"change","label":_vm.$t('user.borough')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'borough',
                {
                  rules: [
                    {
                      required: true,
                      message: _vm.$t('common.requiredValidationError'),
                    } ],
                } ]),expression:"[\n                'borough',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: $t('common.requiredValidationError'),\n                    },\n                  ],\n                },\n              ]"}]})],1),_c('a-form-item',{attrs:{"label":_vm.$t('user.province')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['province']),expression:"['province']"}]})],1),_c('a-form-item',{attrs:{"trigger":"change","label":_vm.$t('user.city')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'city',
                {
                  rules: [
                    {
                      required: true,
                      message: _vm.$t('common.requiredValidationError'),
                    } ],
                } ]),expression:"[\n                'city',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: $t('common.requiredValidationError'),\n                    },\n                  ],\n                },\n              ]"}]})],1),_c('a-form-item',{attrs:{"trigger":"change","label":_vm.$t('user.zipCode')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'zip_code',
                {
                  rules: [
                    {
                      required: true,
                      message: _vm.$t('common.requiredValidationError'),
                    } ],
                } ]),expression:"[\n                'zip_code',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: $t('common.requiredValidationError'),\n                    },\n                  ],\n                },\n              ]"}]})],1),_c('a-form-item',{attrs:{"label":_vm.$t('user.country')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['country', { initialValue: _vm.$t('user.defaultCountry') }]),expression:"['country', { initialValue: $t('user.defaultCountry') }]"}],attrs:{"disabled":""}})],1)],1),_c('a-col',{staticClass:"pl-50",class:_vm.$style.checkboxConfirmWrapper,attrs:{"span":24}},[_c('a-form-item',{class:_vm.$style.confirmationCheckbox,attrs:{"label":_vm.$t('user.promotionMessages')}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'promotion_messages',
                {
                  valuePropName: 'checked',
                } ]),expression:"[\n                'promotion_messages',\n                {\n                  valuePropName: 'checked',\n                },\n              ]"}]})],1),_c('a-form-item',{staticClass:"dataUsageApproval",class:_vm.$style.confirmationCheckbox,attrs:{"label":_vm.$t('user.dataUsageApproval')}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'data_usage_approval',
                {
                  rules: [
                    {
                      validator: _vm.validateDataUssage,
                    } ],
                  valuePropName: 'checked',
                } ]),expression:"[\n                'data_usage_approval',\n                {\n                  rules: [\n                    {\n                      validator: validateDataUssage,\n                    },\n                  ],\n                  valuePropName: 'checked',\n                },\n              ]"}]})],1),_c('a-form-item',{class:_vm.$style.confirmationCheckbox,attrs:{"label":_vm.$t('user.promotionActivity')}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'promotion_activity',
                {
                  valuePropName: 'checked',
                } ]),expression:"[\n                'promotion_activity',\n                {\n                  valuePropName: 'checked',\n                },\n              ]"}]})],1),_c('a-row',{class:_vm.$style.politicallyExposed,attrs:{"span":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{class:_vm.$style.confirmationCheckbox,attrs:{"label":_vm.$t('user.politicalExposure')}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'political_exposure',
                    {
                      valuePropName: 'checked',
                    } ]),expression:"[\n                    'political_exposure',\n                    {\n                      valuePropName: 'checked',\n                    },\n                  ]"}],on:{"change":_vm.showUploadFile}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',[(_vm.politicallyExposed)?_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:(['politically_exposed_doc']),expression:"['politically_exposed_doc']"}],staticClass:"col-6",class:_vm.$style.politicallyExposed,attrs:{"customRequest":function () { return true; },"name":"file","multiple":false},on:{"change":function (doc) { return _vm.handleFileUpload('politically_exposed_doc', doc); }}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" Click to Upload ")],1)],1):_vm._e()],1)],1)],1),_c('a-form-item',{staticStyle:{"width":"50%"},attrs:{"trigger":"change","label":_vm.$t('user.note')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'note',
                {
                  rules: [
                    {
                      required: true,
                      message: _vm.$t('common.requiredValidationError'),
                    } ],
                } ]),expression:"[\n                'note',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: $t('common.requiredValidationError'),\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":_vm.$t('user.userNote'),"auto-size":{ minRows: 5, maxRows: 5 }}})],1)],1)],1),_c('a-col',{staticClass:"pl-50 pr-50",attrs:{"span":8}},[_c('h4',{class:_vm.$style.width100},[_vm._v(_vm._s(_vm.$t('user.accountInfo')))]),_c('a-form-item',{attrs:{"trigger":"change","label":_vm.$t('user.bankAccNumber')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'bank_acc_number',
              {
                rules: [
                  {
                    validator: _vm.validateBankNumber,
                  } ],
              } ]),expression:"[\n              'bank_acc_number',\n              {\n                rules: [\n                  {\n                    validator: validateBankNumber,\n                  },\n                ],\n              },\n            ]"}],attrs:{"maxLength":_vm.bankAccNumberLength},on:{"keydown":_vm.onlyNumbers}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('user.bankName')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['bank_name']),expression:"['bank_name']"}],attrs:{"disabled":""}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('user.sourceIncome')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['source_income']),expression:"['source_income']"}]})],1),_c('a-row',{staticClass:"pt-20",class:_vm.$style.statusWrapper,attrs:{"span":24}},[_c('h4',{class:_vm.$style.width100},[_vm._v(_vm._s(_vm.$t('user.userStatus')))]),_c('a-form-item',{class:_vm.$style.status,attrs:{"label":_vm.$t('user.isActivated'),"labelAlign":"left"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'is_activated',
                {
                  valuePropName: 'checked',
                } ]),expression:"[\n                'is_activated',\n                {\n                  valuePropName: 'checked',\n                },\n              ]"}]})],1),_c('a-form-item',{staticClass:"ml-20",class:_vm.$style.status,attrs:{"label":_vm.$t('user.isValidated'),"labelAlign":"left"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'is_validated',
                {
                  valuePropName: 'checked',
                } ]),expression:"[\n                'is_validated',\n                {\n                  valuePropName: 'checked',\n                },\n              ]"}]})],1)],1),_c('a-row',{attrs:{"span":24}},[_c('a-form-item',{class:_vm.$style.dropdownWrapper,attrs:{"label":_vm.$t('user.chooseDocumentToUpload')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'document_type',
                {
                  rules: [
                    {
                      required: true,
                      message: _vm.$t('common.requiredValidationError'),
                    } ],
                } ]),expression:"[\n                'document_type',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: $t('common.requiredValidationError'),\n                    },\n                  ],\n                },\n              ]"}],class:_vm.$style.dropdownMenu,staticStyle:{"width":"40%"}},[_c('a-select-option',{attrs:{"value":"personal_id"}},[_vm._v(" "+_vm._s(_vm.$t('user.personalId'))+" ")]),_c('a-select-option',{attrs:{"value":"passport"}},[_vm._v(" "+_vm._s(_vm.$t('user.passport'))+" ")])],1)],1)],1),_c('a-row',{class:_vm.$style.presonalIdWrapper,attrs:{"span":24,"gutter":[16, 16]}},[_c('a-form-item',{staticClass:"p-6 m-0",attrs:{"label":_vm.$t('user.idFrontImage')}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'document_image_front',
                {
                  rules: [
                    {
                      required: true,
                      message: _vm.$t('common.requiredValidationError'),
                    } ],
                } ]),expression:"[\n                'document_image_front',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: $t('common.requiredValidationError'),\n                    },\n                  ],\n                },\n              ]"}],attrs:{"name":"image_front","list-type":"picture-card","show-upload-list":false,"customRequest":function () { return false; }},on:{"change":function (ref) {
                          var file = ref.file;

                          return _vm.handleChange('document_image_front', file);
}}},[(_vm.document_image_front)?_c('img',{attrs:{"src":_vm.document_image_front,"alt":"avatar"}}):_c('div',[_c('a-icon',{attrs:{"type":_vm.loading ? 'loading' : 'plus'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("Upload")])],1)])],1),_c('a-form-item',{staticClass:"p-6 m-0",attrs:{"label":_vm.$t('user.idBackImage')}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'document_image_back',
                {
                  rules: [
                    {
                      required: true,
                      message: _vm.$t('common.requiredValidationError'),
                    } ],
                } ]),expression:"[\n                'document_image_back',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: $t('common.requiredValidationError'),\n                    },\n                  ],\n                },\n              ]"}],attrs:{"name":"backImage","list-type":"picture-card","show-upload-list":false,"customRequest":function () { return false; }},on:{"change":function (ref) {
                          var file = ref.file;

                          return _vm.handleChange('document_image_back', file);
}}},[(_vm.document_image_back)?_c('img',{attrs:{"src":_vm.document_image_back,"alt":"avatar"}}):_c('div',[_c('a-icon',{attrs:{"type":_vm.loading ? 'loading' : 'plus'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("Upload")])],1)])],1)],1),_c('a-row',{class:_vm.$style.presonalIdWrapper,attrs:{"gutter":[16, 16]}},[_c('a-form-item',{staticClass:"p-6 m-0",attrs:{"label":_vm.$t('user.uploadPersonPhotography')}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:(['personal_photo']),expression:"['personal_photo']"}],attrs:{"name":"personal_photo","list-type":"picture-card","show-upload-list":false,"customRequest":function () { return false; }},on:{"change":function (ref) {
                          var file = ref.file;

                          return _vm.handleChange('personal_photo', file);
}}},[(_vm.personal_photo)?_c('img',{attrs:{"src":_vm.personal_photo,"alt":"Face Photography"}}):_c('div',[_c('a-icon',{attrs:{"type":_vm.loading ? 'loading' : 'plus'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("Upload")])],1)])],1),_c('a-form-item',{staticClass:"p-6 m-0",attrs:{"label":_vm.$t('user.uploadFaceWithDoc')}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:(['personal_photo_and_document']),expression:"['personal_photo_and_document']"}],attrs:{"name":"personal_photo_and_document","list-type":"picture-card","show-upload-list":false,"customRequest":function () { return false; }},on:{"change":function (ref) {
                          var file = ref.file;

                          return _vm.handleChange('personal_photo_and_document', file);
}}},[(_vm.personal_photo_and_document)?_c('img',{attrs:{"src":_vm.personal_photo_and_document,"alt":"Face Photography"}}):_c('div',[_c('a-icon',{attrs:{"type":_vm.loading ? 'loading' : 'plus'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("Upload")])],1)])],1),_c('a-form-item',{staticClass:"p-6 m-0",attrs:{"label":_vm.$t('user.uploadBankProof')}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:(['bank_proof_doc']),expression:"['bank_proof_doc']"}],attrs:{"name":"bank_proof_doc","list-type":"picture-card","show-upload-list":false,"customRequest":function () { return false; }},on:{"change":function (ref) {
                          var file = ref.file;

                          return _vm.handleChange('bank_proof_doc', file);
}}},[(_vm.bank_proof_doc)?_c('img',{attrs:{"src":_vm.bank_proof_doc}}):_c('div',[_c('a-icon',{attrs:{"type":_vm.loading ? 'loading' : 'plus'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("Upload")])],1)])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }