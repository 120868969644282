
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { WrappedFormUtils } from 'ant-design-vue/types/form/form';

import ImgPreviewModal from './ImgPreviewModal.vue';
import { formatBase64String, getFileFormat, getBase64 } from '@/lib/imgHelpers';
import { UserStore } from '@/modules/userManagement/user.store';
import to from 'await-to-js';

@Component({
  name: 'UserCheckboxFields',
  components: {
    ImgPreviewModal,
  },
})
export default class UserCheckboxFields extends Vue {
  form!: WrappedFormUtils;
  politicallyExposed = UserStore.user.political_exposure;
  politically_exposed_doc = '';
  showPreviewModal = false;

  get userData() {
    return UserStore.user;
  }

  get showPreviewBtn() {
    return this.userData.politically_exposed_doc;
  }

  @Watch('userData')
  resetFormOnDataChanged() {
    this.form.resetFields();
  }

  beforeCreate() {
    this.form = this.$form.createForm(this);
  }

  async handleSubmit() {
    const [formError] = await to(this.form.validateFields() as any);
    if (formError) return;
    const formData = this.form.getFieldsValue();
    if (this.userData.politically_exposed_doc && formData.political_exposure)
      delete formData.political_exposure;
    this.$parent.$emit('submit', formData);
  }

  handleClosePreview() {
    this.showPreviewModal = false;
  }

  showUploadFile({ target }: any) {
    this.politicallyExposed = target.checked;
  }

  handleFileUpload(type: string, doc: any) {
    getBase64(doc.file.originFileObj, (fileUrl: string) => {
      this.form.setFieldsValue({
        [type]: {
          file_base64: formatBase64String(fileUrl),
          file_format: getFileFormat(doc.file.type),
        },
      });
    });
    doc.file.status = 'done';
    if (doc.fileList.length > 1) {
      doc.fileList.shift();
    }
  }

  validatePoliticallyExposedDoc(_rule: any, value: string, callback: Function) {
    if (!this.userData.politically_exposed_doc && !value)
      return callback(this.$t('common.requiredValidationError'));
    callback();
  }

  openPreviewModal() {
    this.showPreviewModal = true;
  }
}
