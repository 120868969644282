
import { Component, Vue, Watch } from 'vue-property-decorator';
import { WrappedFormUtils } from 'ant-design-vue/types/form/form';

import ImgPreviewModal from './ImgPreviewModal.vue';
import { onlyNumbers } from '@/lib/allowOnlyNumbers';
import { UserStore } from '@/modules/userManagement/user.store';
import { isValidAccount, getBankName } from '@/lib/bankAccount';
import { UserInputRule } from '@/modules/userManagement/user.types';
import { formatBase64String, getBase64, getFileFormat } from '@/lib/imgHelpers';
import to from 'await-to-js';

@Component({
  name: 'UserBankDetails',
  components: {
    ImgPreviewModal,
  },
})
export default class UserBankDetails extends Vue {
  form!: WrappedFormUtils;
  bankAccNumberLength = 13;
  bank_proof_doc = '';
  imgToPreview = '';
  showPreviewModal = false;

  onlyNumbers = onlyNumbers;

  get selectedUser() {
    return UserStore.user;
  }

  get userId() {
    return UserStore.userId;
  }

  get showFinancialVerifyBtn() {
    return this.selectedUser.status === 'Financially verifying in progress';
  }

  get initialBankName() {
    return getBankName(this.selectedUser.bank_acc_number || '');
  }

  @Watch('selectedUser')
  onUserDataChanged() {
    this.bank_proof_doc = '';
  }

  beforeCreate() {
    this.form = this.$form.createForm(this);
  }

  validateBankNumber(rule: UserInputRule, value: string, callback: Function) {
    if (!value) {
      return callback(`${this.$t('user.invalidBankAcc')}`);
    }
    if (value && !isValidAccount(value)) {
      this.form.setFieldsValue({ ['bank_name']: '' });
      return callback(`${this.$t('user.invalidBankAcc')}`);
    }
    this.form.setFieldsValue({ ['bank_name']: getBankName(value) });
    callback();
  }

  handleChangePhoto({ file }: any) {
    getBase64(file.originFileObj, (fileUrl: string) => {
      this.form.setFieldsValue({
        bank_proof_doc: {
          file_base64: formatBase64String(fileUrl),
          file_format: getFileFormat(file.type),
        },
      });
      // @ts-ignore: next-line
      this.bank_proof_doc = fileUrl;
    });
  }

  async handleSubmit() {
    const [err] = await to(this.form.validateFields() as any);
    if (err) return;
    const formData = this.form.getFieldsValue();
    this.$parent.$emit('submit', formData);
  }

  async handleFinanciallyValidateAccount() {
    if (!this.userId) return;
    const [err] = await to(UserStore.verifyUserFinances(this.userId));
    if (err) return;
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: this.$t('user.financialValidateSuccess') as string,
    });
  }

  handleClosePreview() {
    this.showPreviewModal = false;
  }

  handleOpenPreview(imgPath: string) {
    this.imgToPreview = imgPath;
    this.showPreviewModal = true;
  }

  resetForm() {
    this.form.resetFields();
    this.bank_proof_doc = '';
  }
}
