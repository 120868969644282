
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { WrappedFormUtils } from 'ant-design-vue/types/form/form';

import { onlyNumbers } from '@/lib/allowOnlyNumbers';
import { InputConfirm } from '@/ui/components/InputConfirm';
import { UserStore } from '@/modules/userManagement/user.store';

@Component({
  name: 'UserAccountManagement',
  components: { InputConfirm },
})
export default class UserAccountManagement extends Vue {
  form!: WrappedFormUtils;

  beforeCreate() {
    this.form = this.$form.createForm(this);
  }

  get userId() {
    return UserStore.userId;
  }

  get userData() {
    return UserStore.user;
  }

  onlyNumbers = onlyNumbers;

  saveChanges() {
    const formData = this.form.getFieldsValue();
    this.$parent.$emit('submit', formData);
  }
}
