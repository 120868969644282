var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{class:_vm.$style.form,attrs:{"form":_vm.form}},[_c('img-preview-modal',{attrs:{"imgName":"politically_exposed_doc","visible":_vm.showPreviewModal,"close":_vm.handleClosePreview}}),_c('a-row',{staticClass:"pt-20",attrs:{"type":"flex","gutter":[32, 32]}},[_c('a-col',{attrs:{"span":23}},[_c('a-form-item',{class:_vm.$style.formItem,attrs:{"label":_vm.$t('user.activatedUser'),"labelAlign":"left"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'is_active',
            {
              initialValue: _vm.userData.is_active,
              valuePropName: 'checked',
            } ]),expression:"[\n            'is_active',\n            {\n              initialValue: userData.is_active,\n              valuePropName: 'checked',\n            },\n          ]"}],class:_vm.$style.checkbox})],1),_c('a-form-item',{class:_vm.$style.formItem,attrs:{"label":_vm.$t('user.promoAccountUnlocked'),"labelAlign":"left"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'promo_wallet_enabled',
            {
              initialValue: _vm.userData.promo_wallet_enabled,
              valuePropName: 'checked',
            } ]),expression:"[\n            'promo_wallet_enabled',\n            {\n              initialValue: userData.promo_wallet_enabled,\n              valuePropName: 'checked',\n            },\n          ]"}],class:_vm.$style.checkbox})],1),_c('a-form-item',{class:_vm.$style.formItem,attrs:{"label":_vm.$t('user.promotionMessages'),"labelAlign":"left"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'promotion_messages',
            {
              initialValue: _vm.userData.promotion_messages,
              valuePropName: 'checked',
            } ]),expression:"[\n            'promotion_messages',\n            {\n              initialValue: userData.promotion_messages,\n              valuePropName: 'checked',\n            },\n          ]"}],class:_vm.$style.checkbox})],1),_c('a-form-item',{class:_vm.$style.formItem,attrs:{"label":_vm.$t('user.dataUsageApproval'),"labelAlign":"left"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'data_usage_approval',
            {
              initialValue: _vm.userData.data_usage_approval,
              valuePropName: 'checked',
            } ]),expression:"[\n            'data_usage_approval',\n            {\n              initialValue: userData.data_usage_approval,\n              valuePropName: 'checked',\n            },\n          ]"}],class:_vm.$style.checkbox})],1),_c('a-form-item',{class:_vm.$style.formItem,attrs:{"label":_vm.$t('user.promotionActivity'),"labelAlign":"left"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'promotion_activity',
            {
              initialValue: _vm.userData.promotion_activity,
              valuePropName: 'checked',
            } ]),expression:"[\n            'promotion_activity',\n            {\n              initialValue: userData.promotion_activity,\n              valuePropName: 'checked',\n            },\n          ]"}],class:_vm.$style.checkbox})],1),_c('a-form-item',{class:_vm.$style.formItem,attrs:{"label":_vm.$t('user.politicalExposure'),"labelAlign":"left"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'political_exposure',
            {
              initialValue: _vm.userData.political_exposure,
              valuePropName: 'checked',
            } ]),expression:"[\n            'political_exposure',\n            {\n              initialValue: userData.political_exposure,\n              valuePropName: 'checked',\n            },\n          ]"}],class:_vm.$style.checkbox,on:{"change":_vm.showUploadFile}})],1),_c('a-form-item',[(_vm.politicallyExposed)?_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'politically_exposed_doc',
            {
              rules: [
                {
                  validator: _vm.validatePoliticallyExposedDoc,
                } ],
            } ]),expression:"[\n            'politically_exposed_doc',\n            {\n              rules: [\n                {\n                  validator: validatePoliticallyExposedDoc,\n                },\n              ],\n            },\n          ]"}],staticClass:"col-6",class:_vm.$style.politicallyExposed,attrs:{"customRequest":function () { return true; },"name":"file","multiple":false},on:{"change":function (doc) { return _vm.handleFileUpload('politically_exposed_doc', doc); }}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" Click to Upload ")],1)],1):_vm._e()],1),(_vm.showPreviewBtn)?_c('div',[_c('span',{class:_vm.$style.previewPhotoBtn,on:{"click":_vm.openPreviewModal}},[_vm._v(_vm._s(_vm.$t('user.preview')))])]):_vm._e(),_c('div',[_c('a-button',{attrs:{"type":"danger","disabled":!_vm.form.isFieldsTouched()},on:{"click":function($event){return _vm.form.resetFields()}}},[_vm._v(" "+_vm._s(_vm.$t('user.reset'))+" ")]),_c('a-button',{class:_vm.$style.btn,attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(_vm._s(_vm.$t('common.save')))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }