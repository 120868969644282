var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{attrs:{"form":_vm.form}},[_c('a-row',{attrs:{"type":"flex"}},[_c('a-col',{staticClass:"pr-30",attrs:{"flex":"1"}},[_c('a-form-item',{attrs:{"label":_vm.$t('user.streetName')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'street_name',
            {
              rules: [
                {
                  required: true,
                  message: _vm.$t('common.requiredValidationError'),
                } ],
              initialValue: _vm.userData.street_name,
            } ]),expression:"[\n            'street_name',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: $t('common.requiredValidationError'),\n                },\n              ],\n              initialValue: userData.street_name,\n            },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":_vm.$t('user.streetNumber')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'street_number',
            {
              rules: [
                {
                  required: true,
                  message: _vm.$t('common.requiredValidationError'),
                } ],
              initialValue: _vm.userData.street_name,
            } ]),expression:"[\n            'street_number',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: $t('common.requiredValidationError'),\n                },\n              ],\n              initialValue: userData.street_name,\n            },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":_vm.$t('user.aptNumber')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['apartment_number', { initialValue: _vm.userData.apartment_number }]),expression:"['apartment_number', { initialValue: userData.apartment_number }]"}]})],1),_c('a-form-item',{attrs:{"label":_vm.$t('user.borough')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'borough',
            {
              rules: [
                {
                  required: true,
                  message: _vm.$t('common.requiredValidationError'),
                } ],
              initialValue: _vm.userData.borough,
            } ]),expression:"[\n            'borough',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: $t('common.requiredValidationError'),\n                },\n              ],\n              initialValue: userData.borough,\n            },\n          ]"}]})],1)],1),_c('a-col',{staticClass:"pr-30",attrs:{"flex":"1"}},[_c('a-form-item',{attrs:{"label":_vm.$t('user.province')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['province', { initialValue: _vm.userData.province }]),expression:"['province', { initialValue: userData.province }]"}]})],1),_c('a-form-item',{attrs:{"label":_vm.$t('user.city')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'city',
            {
              rules: [
                {
                  required: true,
                  message: _vm.$t('common.requiredValidationError'),
                } ],
              initialValue: _vm.userData.city,
            } ]),expression:"[\n            'city',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: $t('common.requiredValidationError'),\n                },\n              ],\n              initialValue: userData.city,\n            },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":_vm.$t('user.zipCode')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'zip_code',
            {
              rules: [
                {
                  required: true,
                  message: _vm.$t('common.requiredValidationError'),
                } ],
              initialValue: _vm.userData.zip_code,
            } ]),expression:"[\n            'zip_code',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: $t('common.requiredValidationError'),\n                },\n              ],\n              initialValue: userData.zip_code,\n            },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":_vm.$t('user.country')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['country', { initialValue: _vm.$t('user.defaultCountry') }]),expression:"['country', { initialValue: $t('user.defaultCountry') }]"}],attrs:{"disabled":""}})],1)],1)],1),_c('a-col',{class:_vm.$style.buttonWrapper,attrs:{"span":24}},[_c('a-button',{attrs:{"type":"danger","disabled":!_vm.form.isFieldsTouched()},on:{"click":function($event){return _vm.form.resetFields()}}},[_vm._v(" "+_vm._s(_vm.$t('user.reset'))+" ")]),_c('a-button',{class:_vm.$style.btn,attrs:{"type":"primary"},on:{"click":_vm.submitForm}},[_vm._v(_vm._s(_vm.$t('common.save')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }