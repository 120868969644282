import { ICellRendererParams } from '@ag-grid-community/core';
import { i18n } from '@/ui/locales/setupLocale';
import { NO_VALUE } from '@/constants';

const UserStatusCell = ({ data }: ICellRendererParams) => {
  if (!data) return NO_VALUE;
  if (data.is_active) return i18n.t('user.active');
  if (data.is_validated) return i18n.t('user.validated');
  return i18n.t('user.notActive');
};

export default UserStatusCell;
