interface BankCodes {
  [key: string]: string;
}

const BANK_CODES: BankCodes = {
  '105': 'AIK BANKA, AD, NIŠ',
  '115': 'MOBI BANKA, AD, BEOGRAD',
  '145': 'EXPOBANK, AD, BEOGRAD',
  '150': 'EUROBANK DIREKTNA, AD, BEOGRAD',
  '155': 'HALKBANK AD BEOGRAD',
  '160': 'BANCA INTESA, AD, BEOGRAD',
  '165': 'ADDIKO BANK, AD, BEOGRAD',
  '170': 'UNICREDIT BANK SRBIJA, AD, BEOGRAD',
  '190': 'ALTA BANKA, AD, BEOGRAD',
  '200': 'BANKA POŠTANSKA ŠTEDIONICA, AD, BEOGRAD',
  '205': 'KOMERCIJALNA BANKA , AD, BEOGRAD',
  '220': 'PROCREDIT BANK, AD, BEOGRAD',
  '250': 'EUROBANK DIREKTNA, AD, BEOGRAD',
  '265': 'RAIFFEISEN BANKA, AD, BEOGRAD',
  '285': 'SBERBANK SRBIJA, AD, BEOGRAD',
  '295': 'SRPSKA BANKA, AD, BEOGRAD',
  '310': 'NLB BANKA, AD, BEOGRAD',
  '325': 'OTP BANKA SRBIJA, AD, NOVI SAD',
  '330': 'CRÉDIT AGRICOLE BANKA SRBIJA, AD, NOVI SAD',
  '340': 'ERSTE BANK, AD, NOVI SAD',
  '360': 'BANKA POŠTANSKA ŠTEDIONICA, AD, BEOGRAD',
  '370': 'OPPORTUNITY BANKA, AD, NOVI SAD',
  '375': 'API BANK, AD, BEOGRAD',
  '380': 'MIRABANK, AD, BEOGRAD',
  '385': 'BANK OF CHINA SRBIJA AD BEOGRAD',
};

export const isValidAccount = (accountNum: string): boolean => {
  const firstThree = accountNum.substring(0, 3);
  return !!BANK_CODES[firstThree] && accountNum.length === 13;
};

export const getBankName = (accountNum: string): string => {
  const firstThree = accountNum.substring(0, 3);
  return BANK_CODES[firstThree];
};
