var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{class:_vm.$style.userDetailsModal,attrs:{"visible":_vm.isModalVisible,"title":_vm.$t('user.changeStatus')},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('div',{class:_vm.$style.modalStatus},[_vm._v(" "+_vm._s(_vm.$t('user.changeStatusTo'))+" "),_c('span',[_vm._v(_vm._s(_vm.status))])]),_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":_vm.$t('user.reasonOfChange')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'note',
          {
            rules: [
              {
                required: true,
                message: _vm.$t('common.requiredValidationError'),
              } ],
          } ]),expression:"[\n          'note',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('common.requiredValidationError'),\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":_vm.$t('user.reasonOfChangeRequired'),"auto-size":{ minRows: 3, maxRows: 5 }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }