
import { Component, Mixins } from 'vue-property-decorator';

import { WithDataTable } from '@/ui/mixins/WithDataTable';
import DateTimeCell from '@/ui/components/CellRenderers/DateTimeCell';
import { UserStore } from '@/modules/userManagement/user.store';
import { UserNoteData } from '@/modules/userManagement/user.types';

@Component
export default class UserNote extends Mixins(WithDataTable) {
  modalVisible = false;
  note = {};

  get tableProps() {
    return {
      columnDefs: [
        {
          headerName: this.$t('user.created'),
          field: 'action_time',
          cellRenderer: DateTimeCell,
          flex: 150,
        },
        {
          headerName: this.$t('user.statusChangedTo'),
          field: 'action_type',
          width: 150,
        },
        { headerName: this.$t('user.boUser'), field: 'bo_username', width: 180 },
        {
          headerName: this.$t('user.reasonOfChange'),
          width: 140,
          field: 'note',
        },
      ],
      rowModelType: 'infinite',
    };
  }

  get tableData() {
    return UserStore.userNotes;
  }

  get totalRecords() {
    return UserStore.totalNotes;
  }

  get userId() {
    return UserStore.userId;
  }

  async fetchData() {
    await UserStore.getUserNotes({
      platform_user_id: this.userId,
      limit: this.limit,
      offset: this.offset,
    });
  }

  openNoteModal({ data }: { data: UserNoteData }) {
    this.note = data;
    this.modalVisible = true;
  }

  closeNoteModal() {
    this.modalVisible = false;
  }
}
