var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{class:_vm.$style.form,attrs:{"form":_vm.form}},[_c('a-row',{staticClass:"pt-20",attrs:{"type":"flex","gutter":[32, 32]}},[_c('a-col',{attrs:{"span":10}},[_c('div',[_c('a-form-item',{class:_vm.$style.formItem,attrs:{"label":_vm.$t('user.accountAccessBlock'),"labelAlign":"left"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'locked_user',
              { initialValue: _vm.userData.locked_user, valuePropName: 'checked' } ]),expression:"[\n              'locked_user',\n              { initialValue: userData.locked_user, valuePropName: 'checked' },\n            ]"}],class:_vm.$style.checkbox})],1)],1),_c('div',{staticClass:"pt-20"},[_c('a-form-item',{class:_vm.$style.formItem,attrs:{"label":_vm.$t('user.blockAllTransactions'),"labelAlign":"left"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'transactions_disabled',
              { initialValue: _vm.userData.transactions_disabled, valuePropName: 'checked' } ]),expression:"[\n              'transactions_disabled',\n              { initialValue: userData.transactions_disabled, valuePropName: 'checked' },\n            ]"}],class:_vm.$style.checkbox})],1)],1),_c('div',{staticClass:"pt-20"},[_c('a-form-item',{class:_vm.$style.formItem,attrs:{"label":_vm.$t('user.blockWithdrawal'),"labelAlign":"left"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'payment_disabled',
              { initialValue: _vm.userData.payment_disabled, valuePropName: 'checked' } ]),expression:"[\n              'payment_disabled',\n              { initialValue: userData.payment_disabled, valuePropName: 'checked' },\n            ]"}],class:_vm.$style.checkbox})],1)],1),_c('div',{staticClass:"pt-20"},[_c('a-form-item',{class:_vm.$style.formItem,attrs:{"label":_vm.$t('user.blockSportsBetsPayment'),"labelAlign":"left"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'sport_bets_disabled',
              { initialValue: _vm.userData.sport_bets_disabled, valuePropName: 'checked' } ]),expression:"[\n              'sport_bets_disabled',\n              { initialValue: userData.sport_bets_disabled, valuePropName: 'checked' },\n            ]"}],class:_vm.$style.checkbox})],1)],1),_c('div',{staticClass:"pt-20"},[_c('a-form-item',{class:_vm.$style.formItem,attrs:{"label":_vm.$t('user.blockGamblingBetsPayment'),"labelAlign":"left"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'gambling_bets_disabled',
              { initialValue: _vm.userData.gambling_bets_disabled, valuePropName: 'checked' } ]),expression:"[\n              'gambling_bets_disabled',\n              { initialValue: userData.gambling_bets_disabled, valuePropName: 'checked' },\n            ]"}],class:_vm.$style.checkbox})],1)],1),_c('div',{staticClass:"pt-20"},[_c('a-form-item',{class:_vm.$style.formItem,attrs:{"label":_vm.$t('user.blockNumberGamesBetsPayment'),"labelAlign":"left"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'number_game_bets_disabled',
              { initialValue: _vm.userData.number_game_bets_disabled, valuePropName: 'checked' } ]),expression:"[\n              'number_game_bets_disabled',\n              { initialValue: userData.number_game_bets_disabled, valuePropName: 'checked' },\n            ]"}],class:_vm.$style.checkbox})],1)],1),_c('a-form-item',[_c('input-confirm',{attrs:{"title":_vm.$t('common.areYouSure')},on:{"confirm":_vm.saveChanges}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(_vm._s(_vm.$t('common.save')))])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }