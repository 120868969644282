
import { Component, Vue, Watch } from 'vue-property-decorator';
import { WrappedFormUtils } from 'ant-design-vue/types/form/form';
import to from 'await-to-js';

import { UserStore } from '@/modules/userManagement/user.store';

@Component
export default class UserAddressDetails extends Vue {
  form!: WrappedFormUtils;

  beforeCreate() {
    this.form = this.$form.createForm(this);
  }

  get userData() {
    return UserStore.user;
  }

  @Watch('userData')
  onUserDataChanged() {
    this.form.resetFields();
  }

  async submitForm() {
    const [formError] = await to(this.form.validateFields() as any);
    if (formError) return;
    const formData = this.form.getFieldsValue();
    this.$parent.$emit('submit', formData);
  }
}
