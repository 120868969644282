
import { Component, Vue } from 'vue-property-decorator';

import ChangeUserModal from '../../ChangeUserDetailsModal.vue';

@Component({
  name: 'NotesWrapper',
  components: {
    ChangeUserModal,
  },
})
export default class NotesWrapper extends Vue {
  isModalVisible = false;
  formData = null;

  mounted() {
    this.$on('submit', (data: any) => {
      this.formData = data;
      this.isModalVisible = true;
    });
  }

  handleOk() {
    this.isModalVisible = false;
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: this.$t('user.editUserSuccess') as string,
    });
  }

  handleCancel() {
    this.isModalVisible = false;
  }
}
