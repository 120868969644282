
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { WrappedFormUtils } from 'ant-design-vue/types/form/form';
import jmbg from 'jmbg';

import { generateSelectOptionsFromArr } from '@/lib/generateSelectOptionsFromArr';
import { UserStore } from '@/modules/userManagement/user.store';
import ImgPreviewModal from './ImgPreviewModal.vue';
import { formatBase64String, getBase64, getFileFormat } from '@/lib/imgHelpers';
import { onlyNumbers } from '@/lib/allowOnlyNumbers';
import { UserInputRule } from '@/modules/userManagement/user.types';
import to from 'await-to-js';

@Component({
  name: 'UserValidationDetails',
  components: {
    ImgPreviewModal,
  },
})
export default class UserValidationDetails extends Vue {
  form!: WrappedFormUtils;
  documentTypeOptions = generateSelectOptionsFromArr(['passport', 'peronal id']);
  genderOptions = generateSelectOptionsFromArr(['male', 'female']);
  document_image_back = '';
  ssnNumberLength = 13;
  document_image_front = '';
  personal_photo = '';
  personal_photo_and_document = '';
  onlyNumbers = onlyNumbers;
  imgToPreview = '';
  showPreviewModal = false;

  get userData() {
    return UserStore.user;
  }

  get userId() {
    return UserStore.userId;
  }

  get showActivateUserBtn() {
    return this.userData.status === 'Verifying in progress';
  }

  get initialDocumentTypeValue() {
    return this.userData.document_type === 'lk' ? 'personal id' : this.userData.document_type;
  }

  @Watch('userData')
  onUserDataChanged() {
    this.resetImgUploadThumbnails();
    this.form.resetFields();
  }

  beforeCreate() {
    this.form = this.$form.createForm(this);
  }

  getBirthdateFromJmbg() {
    const { year, month, day } = jmbg.decode(this.userData.ssn);
    const formattedDateString = `${year}-${month}-${day}`;
    return formattedDateString;
  }

  validateJmbg(_rule: UserInputRule, value: string, callback: Function) {
    const isJmbgValid = jmbg.isValid(value);
    if (value && !isJmbgValid) {
      callback(`${this.$t('user.ssnNotValid')}`);
    }
    if (value && value.length === this.ssnNumberLength && !isJmbgValid) {
      callback(`${this.$t('user.ssnNotValid')}`);
    } else {
      this.form.setFields({
        ['date_of_birth']: {
          value: '',
        },
        ['gender']: {
          value: '',
        },
      });
      callback();
    }
    if (isJmbgValid) {
      const ssnData = jmbg.decode(value);
      this.form.setFields({
        ['date_of_birth']: {
          value: `${ssnData.year}-${ssnData.month}-${ssnData.day}`,
        },
        ['gender']: {
          value: ssnData.gender === 'Male' ? 'male' : 'female',
        },
      });
      callback();
    }
    callback();
  }

  handleChange(type: string, file: any) {
    getBase64(file.originFileObj, (fileUrl: string) => {
      this.form.setFieldsValue({
        [type]: {
          file_base64: formatBase64String(fileUrl),
          file_format: getFileFormat(file.type),
        },
      });
      // @ts-ignore: next-line
      this[type] = fileUrl;
    });
  }

  resetImgUploadThumbnails() {
    this.document_image_back = '';
    this.document_image_front = '';
    this.personal_photo = '';
    this.personal_photo_and_document = '';
  }

  async handleFormSubmit() {
    const [err] = await to(this.form.validateFields() as any);
    if (err) return;
    const formData = this.form.getFieldsValue();

    this.$parent.$emit('submit', formData);
  }

  handleFileChange = (label: string, file: any) => this.handleChange(label, file);

  async handleActivateAccount() {
    if (!this.userId) return;
    const [err] = await to(UserStore.verifyUserAccount(this.userId));
    if (err) return;
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: this.$t('user.validateSuccess') as string,
    });
  }

  handleClosePreview() {
    this.showPreviewModal = false;
  }

  handleOpenPreview(imgPath: string) {
    this.imgToPreview = imgPath;
    this.showPreviewModal = true;
  }
}
