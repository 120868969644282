import { ICellRendererParams } from '@ag-grid-community/core';
import isUndefined from 'lodash/isUndefined';

import { i18n } from '@/ui/locales/setupLocale';
import { NO_VALUE } from '@/constants';

const YesNoCell = ({ value }: ICellRendererParams) => {
  if (isUndefined(value)) return NO_VALUE;
  return value ? i18n.t('common.yes') : i18n.t('common.no');
};

export default YesNoCell;
