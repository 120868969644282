
import { Component, Vue } from 'vue-property-decorator';

import NotesWrapper from './NotesWrapper.vue';
import UserBasicDetails from './UserBasicDetails.vue';
import UserCheckboxFields from './UserCheckboxFields.vue';
import UserValidationDetails from './UserValidationDetails.vue';
import UserAddressDetails from './UserAddressDetails.vue';
import UserBankDetails from './UserBankDetails.vue';
import UserRemoteUsernames from './UserRemoteUsernames.vue';

@Component({
  name: 'UserDetails',
  components: {
    NotesWrapper,
    UserBasicDetails,
    UserCheckboxFields,
    UserValidationDetails,
    UserAddressDetails,
    UserBankDetails,
    UserRemoteUsernames,
  },
})
export default class UserDetails extends Vue {}
