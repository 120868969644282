
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { WrappedFormUtils } from 'ant-design-vue/types/form/form';
import { UserData } from '@/modules/userManagement/user.types';
import to from 'await-to-js';

import { UserStore } from '@/modules/userManagement/user.store';

@Component
export default class ChangeUserModal extends Vue {
  @Prop({ required: true })
  isModalVisible!: boolean;

  @Prop({ required: true })
  formData!: UserData;

  form!: WrappedFormUtils;

  beforeCreate() {
    this.form = this.$form.createForm(this);
  }

  get userId() {
    return UserStore.userId;
  }

  async handleOk() {
    const [formError] = await to(this.form.validateFields() as any);
    if (formError) return;
    const formNote = this.form.getFieldsValue() as object;
    const [err] = await to(
      UserStore.editUser({
        ...formNote,
        ...this.formData,
        platform_user_id: this.userId,
      })
    );
    if (err) return;
    this.$emit('handleOk');
  }

  handleCancel() {
    this.$emit('handleCancel');
  }
}
