import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import to from 'await-to-js';

import store from '@/store';
import {
  UserForm,
  UserData,
  ChangeUserStatus,
  UserNoteData,
  TableDataPayload,
  CreateUser,
  IUserLocksData,
} from './user.types';
import { userService } from './user.service';

@Module({ dynamic: true, store, name: 'users', namespaced: true })
class User extends VuexModule {
  users: UserData[] = [];
  totalUsers = 0;
  user: UserData = {};
  userId = -1;
  notes: UserNoteData[] = [];
  totalNotes = 0;

  get selectedUser() {
    return this.user;
  }

  get userNotes() {
    return this.notes;
  }

  @Mutation
  setUsers({ items, total }: { items: UserData[]; total: number }) {
    this.totalUsers = total;
    this.users = [...this.users, ...items];
  }

  @Mutation
  clearUsersStore() {
    this.users = [];
    this.totalUsers = 0;
  }

  @Mutation
  clearUser() {
    this.userId = -1;
    this.user = {};
  }

  @Mutation
  setUser(payload: UserData) {
    this.user = payload;
  }

  @Mutation
  setActiveId(id: number) {
    this.userId = id;
  }

  @Mutation
  setUserStatus(status: string) {
    this.user.status = status;
  }

  @Mutation
  setNotes({ items, total }: { items: UserNoteData[]; total: number }) {
    this.totalNotes = total;
    this.notes = items;
  }

  @Action
  async updateUserLocks(payload: IUserLocksData) {
    const [err, res] = await to(userService.updateUserLocks(payload));
    if (err) return Promise.reject(err);
    return Promise.resolve(res);
  }

  @Action
  async fetchUsers(payload: UserForm) {
    const [err, res] = await to<any>(userService.fetchUsers(payload));
    if (err) return Promise.reject(err);
    this.setUsers(res);
    return Promise.resolve(res);
  }

  @Action
  async getUserDetails(id: number) {
    const [err, res] = await to<any>(userService.getUserDetails(id));
    if (err) return Promise.reject(err);
    this.setUser(res);
    return Promise.resolve();
  }

  @Action
  async editUser(payload: UserForm) {
    const [err] = await to<any>(userService.editUser(payload));
    if (err) return Promise.reject(err);
    this.getUserDetails(this.userId);
    return Promise.resolve();
  }

  @Action
  async changeUserStatus(payload: ChangeUserStatus) {
    const [err] = await to<any>(userService.changeUserStatus(payload));
    if (err) return Promise.reject(err);
    this.setUserStatus(payload.status);
    return Promise.resolve();
  }

  @Action
  async getUserNotes(payload: TableDataPayload) {
    const [err, res] = await to<any>(userService.getUserNotes(payload));
    if (err) return Promise.reject(err);
    this.setNotes(res);
    return Promise.resolve(res);
  }

  @Action
  async createNewUser(payload: CreateUser) {
    const [err] = await to<any>(userService.createNewUser(payload));
    if (err) return Promise.reject(err);
  }

  @Action
  async verifyUserAccount(userId: number) {
    const [err] = await to<any>(userService.verifyUserAccount(userId));
    if (err) return Promise.reject(err);
    this.setUserStatus('Verified');
    return Promise.resolve();
  }

  @Action
  async verifyUserFinances(userId: number) {
    const [err] = await to<any>(userService.verifyUserFinances(userId));
    if (err) return Promise.reject(err);
    this.setUserStatus('Approved');
    return Promise.resolve();
  }

  @Action
  async fetchUserImg(payload: { platform_user_id: number; file_name: string }) {
    const [err, res] = await to(
      userService.fetchUserImg(payload.platform_user_id, payload.file_name)
    );
    if (err) return Promise.reject(err);

    return Promise.resolve(res);
  }
}

export const UserStore = getModule(User);
