
import { Component, Mixins } from 'vue-property-decorator';

import { WithDataTable } from '@/ui/mixins/WithDataTable';

@Component
export default class UserRemoteUsernames extends Mixins(WithDataTable) {
  get tableProps() {
    return {
      columnDefs: [
        { headerName: this.$t('user.gameSystem'), field: 'gameSystem' },
        { headerName: this.$t('user.remoteUsername'), field: 'remoteUsername' },
      ],
      rowModelType: 'infinite',
    };
  }

  get tableData() {
    return [{ gameSystem: 'neki sistem', remoteUsername: 'neki username' }];
  }

  get totalRecords() {
    return 1;
  }

  get gridOptions() {
    return { rowStyle: { width: '100%' } };
  }
}
