import { api, ApiResponse } from '@/lib/api';
import {
  UserForm,
  ChangeUserStatus,
  TableDataPayload,
  CreateUser,
  IFetchUsersResponse,
  IUserLocksData,
} from './user.types';

const fetchUsers = (payload: UserForm): ApiResponse<IFetchUsersResponse> => {
  return api.post('/user/backoffice/filter-user', payload, {
    params: {
      limit: payload.limit,
      offset: payload.offset,
    },
  });
};

const getUserDetails = (id: number) =>
  api.get('user/bo/get-platform-user', {
    params: {
      platform_user_id: id,
    },
  });

const updateUserLocks = (payload: IUserLocksData) =>
  api.put('user/bo/change-user-details', payload, {
    params: {
      platform_user_id: payload.platform_user_id,
    },
  });

const editUser = (payload: UserForm) => {
  return api.put('user/bo/change-user-details', {
    ...payload,
  });
};

const changeUserStatus = (payload: ChangeUserStatus) => {
  return api.post('user/bo/change-user-status', {
    ...payload,
  });
};

const getUserNotes = (payload: TableDataPayload) =>
  api.get('user/bo/get-all-notes', {
    params: { ...payload },
  });

const createNewUser = (payload: Partial<CreateUser>) =>
  api.post('user/bo/create-platform-user', payload);

const verifyUserAccount = (platform_user_id: number) => {
  return api.post('user/backoffice/verify-platform-user', {
    platform_user_id,
    is_validated: true,
  });
};

const fetchUserImg = (user_id: number, file_name: string) => {
  return api.get(`user/bo/get-user-file?user_id=${user_id}&file_name=${file_name}`);
};

const verifyUserFinances = (platform_user_id: number) => {
  return api.post('user/backoffice/financially-verify-platform-user', {
    platform_user_id,
    is_financially_validated: true,
  });
};

export const userRepo = {
  fetchUsers,
  getUserDetails,
  editUser,
  changeUserStatus,
  getUserNotes,
  createNewUser,
  updateUserLocks,
  verifyUserAccount,
  verifyUserFinances,
  fetchUserImg,
};
