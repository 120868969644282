
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { WrappedFormUtils } from 'ant-design-vue/types/form/form';
import to from 'await-to-js';
import { TranslateResult } from 'vue-i18n';

import { UserStore } from '@/modules/userManagement/user.store';

@Component
export default class UserStatusModal extends Vue {
  @Prop({ required: true })
  isModalVisible!: boolean;

  @Prop({ required: true })
  status!: string;

  @Prop({ required: true })
  id!: number;

  form!: WrappedFormUtils;

  beforeCreate() {
    this.form = this.$form.createForm(this);
  }

  successMessage(description: TranslateResult) {
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: description as string,
    });
  }

  async handleOk() {
    const [formError] = await to(this.form.validateFields() as any);
    if (formError) return;
    const formData = this.form.getFieldsValue() as object;
    const [err] = await to(
      UserStore.changeUserStatus({
        ...formData,
        platform_user_id: this.id,
        status: this.status,
      })
    );
    if (err) return;
    this.$emit('handleOk');
    this.successMessage(this.$t('user.statusChangedSuccessfully'));
  }

  handleCancel() {
    this.$emit('handleCancel');
  }
}
